import { get, isEmpty } from 'lodash'
import { navigate } from 'gatsby'
import {
  useQuery, useQueryClient, useMutation
} from 'react-query'

import useClient from '../../hooks/useClient'

export const useFetchMeQuery = (redirection = true, currentPath = '') => {
  const client = useClient()

  return useQuery(['fetchMe'], async () => {
    return await client.get('/api/users/profile')
  }, {
    select: ({ data }) => data,
    keepPreviousData: true,
    retry: false,
    refetchOnWindowFocus: false,
    onError: () => {
      if (!isEmpty(currentPath)) {
        localStorage.setItem('currentPath', currentPath)
      }

      if (redirection) {
        navigate('/profile/login')
      }
    }
  })
}

export const useMutateUserQuery = (userId) => {
  const client = useClient()
  const queryClient = useQueryClient()

  return useMutation(async (user) => await client.patch(`/api/users/${userId}`, user), {
    onSuccess: async () => {
      await queryClient.refetchQueries(['fetchMe'])
    }
  })
}

export const useMutateUserPwdQuery = () => {
  const client = useClient()

  return useMutation(async (email) => await client.post('/api/users/reset_password_email', {
    email
  }), {
    onSuccess: async () => {

    }
  })
}

export const useMutateUserPwdUpdateQuery = () => {
  const client = useClient()

  return useMutation(async (values) => await client.patch('/api/users/update_password', {
    user: {
      password: get(values, 'password'),
      new_password: get(values, 'newPassword')
    }
  }), {
    onSuccess: async () => {
    }
  })
}
