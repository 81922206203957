import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { get, isEmpty } from 'lodash'
import { Widget } from '@typeform/embed-react'
import { navigate, graphql, useStaticQuery } from 'gatsby'

import SEO from '../../../components/atoms/Seo'
import media from '../../../utils/media'
import Navbar from '../../../components/organisms/Navbar'
import JsonLD from '../../../components/atoms/JsonLD'
import SubmitButton from '../../../components/atoms/SubmitButton'
import useWindowPathname from '../../../utils/useWindowPathname'
import HomeSectionSubtitle from '../../../components/atoms/HomeSectionSubtitle'
import ProgramPlaceTemplate from '../../../components/templates/ProgramPlaceTemplate'
import { useFetchMeQuery } from '../../../queries/users'
import { deepReplace, replaceGraphKey } from '../../../utils/deepReplace'

const TypeForm = styled(Widget)`
  width: 500px;
  height: 500px;

  ${media.lessThan('md')`
    width: 400px;
  `}

  ${media.lessThan('sm')`
    width: 300px;
  `}
`
const Message = styled(HomeSectionSubtitle)`
  margin-bottom: 24px;
`
const MessageTitle = styled(HomeSectionSubtitle)`
  margin: 0;
  margin-top: 32px;
  font-size: 24px;
`
const ErrorContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  margin: 0 10%;
`
const ErrorButton = styled(SubmitButton)`
  width: 300px;
  align-self: center;
`

const AdPlace = ({ location }) => {
  const { site } = useStaticQuery(graphql`
      query {
        site {
          siteMetadata {
            webpage {
              _context
              _type
              name
              url
              relatedLink
              specialty
            }
            organization {
              _context
              _type
              _id
              url
              image
              geo {
                _type
                latitude
                longitude
              }
              address {
                _type
                streetAddress
                addressLocality
                postalCode
                addressCountry {
                  _type
                  name
                }
              }
              name
              knowsAbout
            }
          }
        }
      }
    `)

  const keywords = ['Locaux', 'Libéral', 'Professionnel', 'Rendez-vous', 'experts']
  const linkedData = {
    '@context': 'http://schema.org',
    '@type': 'Project',
    name: 'TrouverMonLocalPro - Rendez-vous avec un expert',
    description: '',
    parentOrganization: {
      '@type': 'Organization',
      name: 'Folhomee',
      location: {
        '@type': 'PostalAddress',
        streetAddress: '44 Rue du Chemin Vert',
        addressLocality: 'Paris',
        postalCode: '75011'
      }
    },
    keywords: keywords.join(', '),
    makesOffer: {
      '@type': 'Offer'
    }
  }

  const { webpage, organization } = get(deepReplace(site, replaceGraphKey), 'siteMetadata')
  const title = 'Déposer une annonce - Locaux professions libérales'
  const description = 'Déposer une annonce pour un local professionnel. Vendre un local, louer un local, sous-louer un local.'

  const returnUrl = useWindowPathname()
  const { data, error, isFetching } = useFetchMeQuery(false, returnUrl)

  const isBlocked = get(data, 'user.blocked', false)

  return (
    <>
      <SEO
        title={title}
        description={description}>
        <JsonLD>
          {[webpage, organization, linkedData]}
        </JsonLD>
      </SEO>
      <Navbar location={location} />
      <ProgramPlaceTemplate>
        {!isEmpty(error) &&
          <ErrorContainer>
            <Message>
              Rejoignez la communauté Trouver Mon Local pro afin de mettre en avant votre local auprès de milliers de professionnels libéraux en France
            </Message>
            <ErrorButton
              color='brightOrange'
              onClick={() => navigate('/profile/login')}>
              Se connecter
            </ErrorButton>
            <ErrorButton
              color='blue'
              onClick={() => navigate('/profile/signin')}>S'inscrire
            </ErrorButton>
          </ErrorContainer>
        }
        {isEmpty(error) && !isFetching && isBlocked &&
          <ErrorContainer>
            <MessageTitle>Vous avez déjà atteint le nombre maximum d’annonces en ligne sur votre compte.</MessageTitle>
            <Message>
              Pour continuer à publier, veuillez mettre en pause l’une de vos annonces actives ou contactez-nous afin d’augmenter votre abonnement.            </Message>
            <ErrorButton
              color='brightOrange'
              onClick={() => navigate('/nous-contacter/?subject=Nombre maximum d’annonces atteint')}>
              Nous contacter
            </ErrorButton>
          </ErrorContainer>
        }
        {isEmpty(error) && !isFetching && !isBlocked &&
          <TypeForm
            id='IhofaX3K'
            hidden={{
              user_role: get(data, 'user.is_pro', false) ? 'pro' : 'client',
              user_email: get(data, 'user.email', ''),
              user_phone: get(data, 'user.phone', ''),
              user_last_name: get(data, 'user.lastname', ''),
              user_first_name: get(data, 'user.firstname', '')
            }}
            disableScroll={true} />}
      </ProgramPlaceTemplate>
    </>
  )
}

AdPlace.propTypes = {
  location: PropTypes.object.isRequired
}

export default AdPlace
