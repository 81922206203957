import axios from 'axios'
import { useRef } from 'react'
import { isEmpty } from 'lodash'

import { API_SALES_URL } from '../config'

const useClient = () => {
  let userToken = ''
  if (typeof window !== 'undefined') {
    userToken = localStorage.getItem('token')
  }

  const instance = useRef(axios.create({
    baseURL: API_SALES_URL,
    timeout: 30000,
    headers: {
      ...(isEmpty(userToken) ? {} : { Authorization: userToken })
    }
  }))

  return instance.current
}

export default useClient
