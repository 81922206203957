import styled from 'styled-components'
import { get } from 'lodash'

const SubmitButton = styled.button`
  width: 100%;
  font-family: 'Source Sans Pro';
  border-radius: 24px;
  background-color: ${({ theme, color }) => get(theme, color || 'brightOrange', '#fff')};
  border: none;
  color: white;
  padding: 5px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  cursor: pointer;
  min-height: 45px;
`

export default SubmitButton
